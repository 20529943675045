import React, { useState, useRef } from "react";
import styles from "./GreetingGenerator.module.css";

import { GrUpdate } from "react-icons/gr";
import { FaCopy } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";

// const flagImg = require("/img/china-flag.png");

const MidAutumnGreetingGenerator = () => {
  const [name, setName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [gratitude, setGratitude] = useState("");
  const [wishes, setWishes] = useState("");
  const [style, setstyle] = useState("");
  const [generatedMessage, setGeneratedMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasReault, setHasResult] = useState(false);
  const textAreaRef = useRef(null);
  const [isRewriting, setIsReWriting] = useState(false);

  // 处理表单提交，生成个性化祝福语
  const handleGenerate = async () => {
    setGeneratedMessage("");
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    // console.log(serverBaseUrl);
    if (name && relationship) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${serverBaseUrl}/api/test/MidAutumnGreeting/`,
          {
            name,
            relationship,
            gratitude,
            wishes,
            style,
          }
        );
        setGeneratedMessage(response.data.generatedMessage);
        console.log(response.data.generatedMessage);
        setHasResult(true);
      } catch (error) {
        setHasResult(false);
        console.error("Error generating greeting:", error);
        alert("生成祝福语时发生错误，请稍后重试。");
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("请填写姓名和关系！");
    }
  };
  // 处理表单提交，生成个性化祝福语
  const handleRewriteGenerate = async () => {
    setIsReWriting(true);
    setGeneratedMessage("正在重写中...");
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    // console.log(serverBaseUrl);
    if (name && relationship) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${serverBaseUrl}/api/test/MidAutumnGreeting/`,
          {
            name,
            relationship,
            gratitude,
            style,
          }
        );
        setGeneratedMessage(response.data.generatedMessage);
        console.log(response.data.generatedMessage);
        setHasResult(true);
      } catch (error) {
        setHasResult(false);
        console.error("Error generating greeting:", error);
        alert("生成祝福语时发生错误，请稍后重试。");
      } finally {
        setIsLoading(false);
        setIsReWriting(false);
      }
    } else {
      alert("请填写姓名和关系！");
    }
  };

  // 处理复制功能
  const handleCopy = () => {
    if (generatedMessage) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(generatedMessage)
          .then(() => alert("祝福语已成功复制，快发送给他吧！"))
          .catch(() => fallbackCopyTextToClipboard());
      } else {
        fallbackCopyTextToClipboard();
      }
    }
  };

  const fallbackCopyTextToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      try {
        document.execCommand("copy");
        alert("祝福语已成功复制，快发送给他吧！");
      } catch (err) {
        alert("复制失败，请手动复制文本。");
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        <img className={styles.flagImg} src="img/china-flag.png" alt="" />
        国庆祝福生成器
      </h1>
      {!hasReault && (
        <div className={styles.inputContainer}>
          <div className={styles.NoteAndInputcontainer}>
            <p className={styles.note}>他的姓名\昵称</p>
            <input
              type="text"
              placeholder="老婆、刘总、王老师..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.NoteAndInputcontainer}>
            <p className={styles.note}>他是你的什么人？</p>
            <input
              type="text"
              placeholder="领导、客户、同事、朋友、家人..."
              value={relationship}
              onChange={(e) => setRelationship(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.NoteAndInputcontainer}>
            <p className={styles.note}>
              感恩他的事? <div className={styles.selectNote}>(可选)</div>
            </p>
            <input
              type="text"
              placeholder="在家庭的付出、工作的付出..."
              value={gratitude}
              onChange={(e) => setGratitude(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.NoteAndInputcontainer}>
            <p className={styles.note}>
              想祝福他的话? <div className={styles.selectNote}>(可选)</div>
            </p>
            <input
              type="text"
              placeholder="发大财、升职、身体健康..."
              value={wishes}
              onChange={(e) => setWishes(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.NoteAndInputcontainer}>
            <p className={styles.note}>
              祝福风格 <div className={styles.selectNote}>(可选)</div>
            </p>
            <input
              type="text"
              placeholder="简短、古风、藏头诗..."
              value={style}
              onChange={(e) => setstyle(e.target.value)}
              className={styles.input}
            />
          </div>
        </div>
      )}
      {/* {!generatedMessage && (
        <button onClick={handleGenerate} className={styles.button}>
          Ai一键生成
        </button>
      )} */}

      {!hasReault && (
        <button
          onClick={handleGenerate}
          className={styles.button}
          disabled={isLoading}
        >
          {isLoading ? "生成中..." : "Ai一键生成"}
        </button>
      )}

      {hasReault && (
        <>
          <button
            className={styles.backButton}
            disabled={isRewriting}
            onClick={() => {
              setHasResult(false);
            }}
          >
            <IoMdArrowBack className={styles.backIcon} />
          </button>
          <div className={styles.result}>
            {/* <p className={styles.resultTitle}>生成的祝福语：</p> */}
            <textarea
              ref={textAreaRef}
              value={generatedMessage}
              readOnly
              className={styles.resultText}
            />
            <div className={styles.resultButtonContainer}>
              <button
                className={styles.rewriteButton}
                onClick={handleRewriteGenerate}
              >
                <GrUpdate className={styles.rewriteIcon} />
                重写
              </button>
              <button
                className={styles.copyButton}
                disabled={isRewriting}
                onClick={handleCopy}
              >
                <FaCopy className={styles.copyIcon} />
                复制
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MidAutumnGreetingGenerator;
